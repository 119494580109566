import React from 'react';

function OfflineMessage() {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>You are Offline</h1>
      <p style={styles.message}>Please check your internet connection or refresh your page. If the survey is still not available then wait for sometime and try again</p>
    </div>
  );
}

const styles = {
  container: {
    textAlign: 'center',
    padding: '20px',
    backgroundColor: '#f5f5f5',
  },
  title: {
    fontSize: '24px',
    color: 'red',
  },
  message: {
    fontSize: '16px',
  },
};

export default OfflineMessage;

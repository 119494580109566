// src/Dropdown.js
import React, { useEffect, useState } from "react";
import "./Dropdown.css";
import Select from "react-select";

const Dropdown = ({ options, setSelectedLanguage, selectedLangauge }) => {
  function transformKeys(data) {
    return data.map((item) => ({
      value: item.answer_code,
      label: item.answer,
    }));
  }
  const [selectedOption, setSelectedOption] = useState(
    transformKeys(options).find((val) => {
      return val.value === selectedLangauge.answer_code;
    })
  );

  console.log(selectedLangauge, "selectedLangauge");

  // const handleChange = (event) => {
  //   setSelectedLanguage({ answer_code: event.target.value });
  //   setSelectedOption(event.target.value);
  // };

  const handleChange = (val) => {
    setSelectedOption(val);
    setSelectedLanguage({ answer_code: val?.value });
  };

  useEffect(() => {
    setSelectedOption(
      transformKeys(options).find((val) => {
        return val.value === selectedLangauge.answer_code;
      })
    );
  }, [selectedLangauge]);

  const handleFocus = () => {
    disableZoom();
  };

  const handleBlur = () => {
    enableZoom();
  };

  return (
    selectedLangauge.answer_code?
    <div className="flex items-center bg-white h-[70px] justify-end gap-4 p-4">
      <div className="mobile:text-xs">Select language :</div>
      <div>
        <Select
          onFocus={handleFocus}
          onBlur={handleBlur}
          styles={{
            control: (baseStyles: any) => ({
              ...baseStyles,
              borderColor: "#96a5b8",
              fontSize: "14px",
              width: "200px",
              "@media (max-width: 600px)": {
                fontSize: "12px",
                padding: "4px",
                height: "30px",
                width: "150px",
              },
            }),
            menu: (provided: any) => ({
              ...provided,
              zIndex: 9999, // Ensure the dropdown has a high z-index
              fontSize: "14px",
              "@media (max-width: 600px)": {
                fontSize: "12px",
              },
            }),
            placeholder: (provided: any) => ({
              ...provided,
              fontSize: "14px",
              "@media (max-width: 600px)": {
                fontSize: "12px",
              },
            }),
            singleValue: (provided: any) => ({
              ...provided,
              fontSize: "14px",
              "@media (max-width: 600px)": {
                fontSize: "12px",
              },
            }),
            valueContainer: (baseStyles: any) => ({
              ...baseStyles,
              padding: "0 8px", // Default padding
              "@media (max-width: 600px)": {
                padding: "0 4px", // Reduced padding for mobile
              },
            }),
            input: (baseStyles: any) => ({
              ...baseStyles,
              margin: "0px", // Remove default margin
              "@media (max-width: 600px)": {
                margin: "0px", // Remove default margin for mobile
              },
            }),

            indicatorsContainer: (baseStyles: any) => ({
              ...baseStyles,

              "@media (max-width: 600px)": {
                height: "30px", // Reduced height for mobile
              },
            }),
          }}
          placeholder={"Select Language"}
          options={transformKeys(options)}
          onChange={handleChange}
          value={selectedOption}
          theme={(theme: any) => ({
            ...theme,
            borderRadius: 10,
            colors: {
              ...theme.colors,
              primary: "#5d5fef",
            },
          })}
        />
      </div>
    </div> :null
  );
};

export default Dropdown;

// ProgressBar.js

import React from "react";
import PropTypes from "prop-types";
import "./ProgressBar.css";
import "normalize.css";

const ProgressBar = ({ completed, total }) => {
  const progress = Math.floor((completed / total) * 100);

  return (
    <div className="w3-container">
      <div className="w3-light-grey">
        <div
          className="w3-green h-2.5 mobile:h-2"
          style={{ width: `${progress}%` }}
        ></div>
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  completed: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

export default ProgressBar;

import React, { useState, useEffect } from "react";
import "./App.css";

import { useMediaQuery } from "react-responsive";
import DesktopView from "./component/Desktop";
import MobileView from "./component/Mobile";
import TabletView from "./component/Tablet";
import OfflineMessage from "./component/Offline";

function App() {
  localStorage.clear();
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  const isTablet = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1223px)",
  });

  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });

  return (
    <div>
      <DesktopView />
      {/* {isTablet && <TabletView />}
      {isMobile && !isTablet && <MobileView />} */}
    </div>
  );
}

export default App;

import React, { useState } from "react";
import Modal from "react-modal";
import "normalize.css";
import close from "../../assets/close.svg";
import stronglyAgree from "../../assets/strongly_agree.gif";
import agree from "../../assets/agree.gif";
import neutral from "../../assets/neutral.gif";
import sad from "../../assets/sad.gif";
import stronglySad from "../../assets/strongly_sad.gif";

const questions = [
  { image: stronglyAgree, question: "Strongly Agree" },
  { image: agree, question: "Agree" },
  { image: neutral, question: "Neither Agree nor Disagree" },
  { image: sad, question: "Disagree" },
  { image: stronglySad, question: "Strongly Disagree" },
];
const LanguageModal = ({ isOpen, closeModal, isInfo }) => {
  const [selectedLanguage, setSelectedLanguage] = useState("en");

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
    closeModal();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const modalStyles = {
    content: {
      width: "50%", // Set the width to 10% of the viewport height
      padding: "49px 52px",
      borderRadius: "16px",
      height: "fit-content",
      border: "1px solid #5D5FEF",
      inset: "auto",
    },
    overlay: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Language Modal"
      style={modalStyles}
    >
      {isInfo ? (
        <>
          <div className="flex justify-between">
            <div className="lg:text-base md:text-base mobile:text-sm xs:text-sm font-bold">
              Instructions:
            </div>
            <img
              onClick={() => closeModal()}
              className="cursor-pointer"
              src={close}
              alt="close-icon"
            />
          </div>

          <div className="h-0.5 my-6 bg-underlineColor w-auto"></div>
          <ol className="list-decimal font-medium lg:text-base md:text-base mobile:text-xs ">
            <li className="lg:mb-6 md:mb-6 mobile:mb-3">
              The conversation between us will remain confidential and no one
              ever will get to know about it.
            </li>
            <li className="lg:mb-6 md:mb-6 mobile:mb-3">
              The questions that I am going to ask you will have 5 response
              options represented by 5 different emoji's.
            </li>
          </ol>

          <ol className="flex flex-col gap-8">
            {questions.map((questionObj, index) => (
              <div
                className="flex items-center gap-2.5 font-medium lg:text-base md:text-base mobile:text-xs mobile:text-xs"
                key={index}
              >
                <img
                  className="lg:w-8= md:w-6 mobile:w-6"
                  src={questionObj?.image}
                  alt="agree-gif"
                />
                <li key={index}>
                  <span>represents </span>
                  <span className="text-primaryColor">
                    "{questionObj.question}"
                  </span>
                </li>
              </div>
            ))}
          </ol>
        </>
      ) : (
        <>
          {" "}
          <h2>Language Change</h2>
          <form onSubmit={handleSubmit}>
            <label htmlFor="languageSelect">Select Language:</label>
            <select
              id="languageSelect"
              value={selectedLanguage}
              onChange={handleLanguageChange}
            >
              <option value="en">English</option>
              <option value="fr">French</option>
              <option value="es">Spanish</option>
            </select>
            <button type="submit">Change Language</button>
          </form>
          <button onClick={closeModal}>Cancel</button>
        </>
      )}
    </Modal>
  );
};

export default LanguageModal;

import React, { useEffect, useRef, useState } from "react";
import Question from "./Question";
import Answer from "./Answer";
import editIcon from "../../assets/icons8-edit.svg";
import "./QuestionAnswer.css";
import "normalize.css";

export default function QuestionAnswer(props) {
  const {
    questionObject,
    answerSelectionCallback,
    startSurvey,
    predfinedQuestionsForComment,
    onEditClick,
    userInput,
    editIsOn,
    editedQuestionId,
    setEditedQuestionId,
    showMessageList,
    setShowMessagesList,
    customLTypeQuestion,
  } = props;
  const [isEdit, setIsEdit] = useState(false);
  const [showAnswer, setShowAnswer] = useState(false);
  const chatThreadRef = useRef(null);
  const regex = /\b.*comment/;

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowAnswer(true);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [editIsOn, showAnswer, showMessageList, customLTypeQuestion]);

  useEffect(() => {
    chatThreadRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
  }, [
    chatThreadRef.current,
    userInput,
    showMessageList,
    setShowMessagesList,
    customLTypeQuestion,
  ]);

  function answerCallBack(ans, qid, gid, type) {
    answerSelectionCallback(ans, qid, gid, type, isEdit);
    setIsEdit(false);
    setEditedQuestionId(0);
  }

  function DetectGivenAnswer(question) {
    if (
      !question?.type &&
      question?.answers?.length > 0 &&
      !startSurvey &&
      !startSurvey?.isSurveyStart &&
      question?.givenAnswer?.answer
    ) {
      return (
        <Answer
          showAnswer={showAnswer}
          isComment={true}
          onAnswerClick={answerSelectionCallback}
          answer={question?.givenAnswer}
        />
      );
    }

    if (
      !question?.type &&
      question?.answers?.length > 0 &&
      !startSurvey?.isSurveyStart
    ) {
      return (
        <Answer
          showAnswer={showAnswer}
          isStart={true}
          onAnswerClick={answerSelectionCallback}
          answer={question?.answers}
        />
      );
    }

    if (
      !question?.type &&
      question?.answers?.length > 0 &&
      startSurvey?.isSurveyStart
    ) {
      return (
        <Answer
          showAnswer={showAnswer}
          isComment={true}
          onAnswerClick={answerSelectionCallback}
          answer={startSurvey}
        />
      );
    }

    if (
      !question?.type &&
      question?.answers?.length > 0 &&
      userInput !== null
    ) {
      return (
        <Answer
          showAnswer={showAnswer}
          isComment={true}
          answer={{ comment: userInput }}
        ></Answer>
      );
    }

    if (
      question?.type === "T" &&
      question?.givenAnswer &&
      question?.givenAnswer?.answer_code === null
    ) {
      return (
        <>
          {question?.givenAnswer?.comment &&
          question?.givenAnswer?.comment !== null ? (
            <div className="answer-wrapper">
              {" "}
              <Answer
                showAnswer={showAnswer}
                type={question?.type}
                qid={question?.qid}
                gid={question?.gid}
                answer={question?.givenAnswer}
                isComment={true}
              />
              <img
                alt="edit"
                onClick={() => {
                  onEditClick(questionObject.qid, true, questionObject.gid);
                }}
                className="editIcon"
                src={editIcon}
              ></img>
            </div>
          ) : null}
        </>
      );
    }

    if (!question?.type) {
      return null;
    }

    if (
      (question?.type === "L" &&
        question?.givenAnswer &&
        question?.givenAnswer?.answer_code === null) ||
      (question.type === "L" &&
        question?.qid &&
        question?.qid === editedQuestionId?.qid)
    ) {
      return (
        <>
          {" "}
          <Answer
            showAnswer={showAnswer}
            customLTypeQuestion={customLTypeQuestion}
            type={question?.type}
            isStart={question?.type === "O" ? true : false}
            qid={question?.qid}
            gid={question?.gid}
            onAnswerClick={answerCallBack}
            answer={question?.answer}
          />{" "}
        </>
      );
    }

    if (
      question.type === "O" &&
      question?.qid &&
      question?.qid === editedQuestionId?.qid
    ) {
      return (
        <>
          {" "}
          <Answer
            showAnswer={showAnswer}
            customLTypeQuestion={customLTypeQuestion}
            type={question?.type}
            isStart={question?.type === "O" ? true : false}
            qid={question?.qid}
            gid={question?.gid}
            onAnswerClick={answerCallBack}
            answer={question?.answer}
          />{" "}
        </>
      );
    }

    if (
      (question?.type === "L" &&
        question?.givenAnswer &&
        question?.givenAnswer?.answer_code !== null) ||
      (question?.type === "L" &&
        question?.qid &&
        question?.qid === editedQuestionId?.qid)
    ) {
      return (
        <div className="answer-wrapper">
          {" "}
          <Answer
            showAnswer={showAnswer}
            customLTypeQuestion={customLTypeQuestion}
            answer={question?.givenAnswer}
            type={question?.type}
            qid={question?.qid}
            gid={question?.gid}
          ></Answer>{" "}
          <img
            alt="edit"
            onClick={() => {
              onEditClick(questionObject.qid, false, questionObject.gid);
            }}
            className="editIcon"
            src={editIcon}
          ></img>{" "}
        </div>
      );
    }

    if (
      question?.type !== "L" &&
      question?.givenAnswer &&
      question?.givenAnswer?.answer_code === null
    ) {
      return (
        <>
          {question?.givenAnswer?.comment !== null ? (
            <>
              {" "}
              <Answer
                showAnswer={showAnswer}
                type={question?.type}
                qid={question?.qid}
                gid={question?.gid}
                answer={question?.givenAnswer}
                isComment={true}
              ></Answer>
            </>
          ) : null}
          {question?.givenAnswer?.answer_code === null ? (
            <>
              {" "}
              <Answer
                showAnswer={showAnswer}
                isStart={true}
                onAnswerClick={answerCallBack}
                type={question?.type}
                qid={question?.qid}
                gid={question?.gid}
                answer={question?.answer}
              ></Answer>
            </>
          ) : null}
        </>
      );
    }

    if (
      question?.type !== "L" &&
      question?.givenAnswer &&
      question?.givenAnswer?.answer_code !== null
    ) {
      return (
        <>
          {question?.givenAnswer?.answer_code ? (
            <div className="answer-wrapper">
              {" "}
              <Answer
                isStart={true}
                showAnswer={showAnswer}
                onAnswerClick={answerCallBack}
                type={question?.type}
                qid={question?.qid}
                gid={question?.gid}
                answer={question?.givenAnswer}
              />{" "}
              <img
                alt="edit"
                onClick={() => {
                  onEditClick(
                    questionObject.qid,
                    question?.givenAnswer?.comment !== null ? true : false,
                    questionObject.gid
                  );
                }}
                className="editIcon"
                src={editIcon}
              ></img>{" "}
            </div>
          ) : question?.givenAnswer?.comment !== null ? (
            <div className="answer-wrapper">
              {" "}
              <Answer
                showAnswer={showAnswer}
                type={question?.type}
                qid={question?.qid}
                gid={question?.gid}
                answer={question?.givenAnswer}
                isComment={true}
              />{" "}
              <img
                alt="edit"
                onClick={() => {
                  onEditClick(
                    questionObject.qid,
                    question?.givenAnswer?.comment !== null ? true : false,
                    questionObject.gid
                  );
                }}
                className="editIcon"
                src={editIcon}
              ></img>{" "}
            </div>
          ) : null}
        </>
      );
    }
  }

  return (
    <div className="flex flex-col mb-4" id={`question-${questionObject.qid}`}>
      <Question question={questionObject?.question} />
      {regex.test(questionObject?.qid) ? (
        <Question question={predfinedQuestionsForComment?.question}></Question>
      ) : null}
      <div
        ref={chatThreadRef}
        key={questionObject?.qid}
        className="questionAnswer"
      >
        {showAnswer && DetectGivenAnswer(questionObject)}
      </div>
    </div>
  );
}

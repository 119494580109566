import React from "react";
import "./Answer.css";
import "normalize.css";
import stronglyAgree from "../../assets/strongly_agree.gif";
import agree from "../../assets/agree.gif";
import neutral from "../../assets/neutral.gif";
import sad from "../../assets/sad.gif";
import stronglySad from "../../assets/strongly_sad.gif";

const mapValuesOfEmojies = {
  1: stronglySad,
  2: sad,
  3: neutral,
  4: agree,
  5: stronglyAgree,
};

// const mapValuesOfEmojies = {
//   5: "😀",
//   4: "🙂",
//   3: "😐",
//   2: "🙁",
//   1: "😞",
// };

export default function Answer(props) {
  const {
    answer,
    onAnswerClick,
    qid,
    gid,
    type,
    isComment,
    isStart,
    customLTypeQuestion,
  } = props;
  console.log(answer, "answer");

  return (
    <>
      {Array.isArray(answer) ? (
        isStart || customLTypeQuestion ? (
          <>
            <div className="startSurveyContainer">
              {answer.map((ans, index) => {
                return (
                  <a
                    key={index}
                    className="startBtn mobile:text-xs mobile:py-2 mobile:px-4 text-base px-4 py-3"
                    onClick={() => {
                      // onAnswerClick(qid,gid,ans.answer_code,ans.answer,ans.aid,type);
                      onAnswerClick(ans, qid, gid, type);
                    }}
                  >
                    {" "}
                    {ans.answer}
                  </a>
                );
              })}
            </div>
          </>
        ) : (
          <div
            className={
              type === "L" ? "mine replyMessagesEmojies" : "mine replyMessages"
            }
          >
            <div
              className={
                type === "L" ? "replyMessagesEmojies" : "replyMessages"
              }
            >
              {answer
                .slice()
                .reverse()
                .map((ans) => {
                  // eslint-disable-next-line
                  return (
                    <a
                      key={ans.aid}
                      onClick={() => {
                        // onAnswerClick(qid,gid,ans.answer_code,ans.answer,ans.aid,type);
                        onAnswerClick(ans, qid, gid, type);
                      }}
                      className={type === "L" ? "btnEmoji" : "btn"}
                    >
                      {" "}
                      {type === "L" ? (
                        <img
                          className="emoji-options w-10 mobile:w-8"
                          src={mapValuesOfEmojies[`${ans?.answer_code}`]}
                          alt=""
                        />
                      ) : (
                        ans?.answer
                      )}{" "}
                    </a>
                  );
                })}
            </div>
          </div>
        )
      ) : (
        <div
          className={
            type === "L" && !customLTypeQuestion
              ? "mine replyMessagesEmojies"
              : "mine replyMessages"
          }
        >
          <div
            className={
              type === "L" && !customLTypeQuestion
                ? "mine replyMessagesEmojies last"
                : "mine replyMessagesAnsweres last text-base mobile:text-xs font-medium py-4 px-6 mobile:py-3.5 mobile:px-4"
            }
          >
            {type === "L" && !customLTypeQuestion ? (
              <img
                className="w-10 mobile:w-8"
                src={mapValuesOfEmojies[`${answer?.answer_code}`]}
                alt="icons"
              />
            ) : isComment ? (
              answer?.comment
            ) : type === "L" ? (
              answer?.answer
            ) : (
              answer?.answer_code?.answer
            )}
          </div>
        </div>
      )}
    </>
  );
}
